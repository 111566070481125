import "./App.css";
import gif from "./abc.gif";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={gif} className="App-logo" alt="logo" />
        <p className="App-text">
          Hi There 👋, we are curating a new experience for you
        </p>
        <p className="App-text">See You Soon 🙋</p>
      </header>
    </div>
  );
}

export default App;
